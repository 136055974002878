import React, { Component } from 'react'

export default class Strategies extends Component {
  render() {
    return (
      <div className='p-5'>
        <h3 className='text-center'>Coming soon..</h3>
      </div>
    )
  }
}